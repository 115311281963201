import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Content from '../components/content';
import Mobile from '../components/icons/mobile';
import Email from '../components/icons/email';
import { data } from '../data/data';

import '../styles/contacts.scss';

const Contacts = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <Layout>
        <Content>
          <h1>За контакти:</h1>
          <ul className="contacts">
            <li>
              <Mobile />
              <a href={`tel:${data.phone.cecoPrefix}`}>{data.phone.ceco}</a>
            </li>
            <li>
              <Mobile />
              <a href={`tel:${data.phone.miroPrefix}`}>{data.phone.miro}</a>
            </li>
            <li className="email-area">
              <Email />
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </li>
          </ul>
        </Content>
      </Layout>
    </Fragment>
  );
};

export default Contacts;
